import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { ReactElement } from "react";

interface FileUploadProps {
  setFileForUpload: (file: File) => void;
}

export default function FileUpload(props: FileUploadProps): ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Upload excel file</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <Button variant="outlined" color="primary" component="label">
              <span className="button-text-right">Select file</span>
              <CloudUploadIcon />
              <input
                hidden
                type="file"
                name="file"
                onChange={(event) => {
                  if (event.target.files?.length) {
                    props.setFileForUpload(event.target.files[0]);
                  }
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
