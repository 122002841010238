import gql from "graphql-tag";

export const GenerateAzureBlobUrl = gql`
  query ($extension: String!, $fieldId: ID!, $projectId: ID!) {
    petrophysicsGenerateUploadBlobSASUrl(
      extension: $extension
      fieldId: $fieldId
      projectId: $projectId
    )
  }
`;
