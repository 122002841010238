import * as actions from "../actions/actionTypes";
import { takeEvery } from "redux-saga/effects";

import {
  fetchCaseSubmissionSaga,
  uploadFileAndSubmitFormSaga,
} from "./caseSubmissionSagas";

export function* watchAll() {
  yield takeEvery(actions.FETCH_CASESUBMISSION_SAGA, fetchCaseSubmissionSaga);
  yield takeEvery(
    actions.START_UPLOAD_AND_SUBMIT_SAGA,
    uploadFileAndSubmitFormSaga,
  );
}
