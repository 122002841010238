import { TableCell, TextField } from "@mui/material";
import { ReactElement } from "react";

interface TableDateCellProps {
  value: string;
  onChange: (value: string) => any;
}

export default function TableDateCell(props: TableDateCellProps): ReactElement {
  return (
    <TableCell>
      <TextField
        fullWidth
        variant="standard"
        type="date"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </TableCell>
  );
}
