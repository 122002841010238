import Grid from "@mui/material/Grid";
import React from "react";
import { connect } from "react-redux";
import {
  getCaseSubmission,
  loadNewCaseSubmissionForm,
  resetLoadingFormStatus,
  startUploadAndFormSubmit,
} from "../../actions/caseSubmissionActions";
import CustomCircularProgress from "../../common/CustomCircularProgress";
import CaseSubmissionInformation from "./CaseSubmissionInformation";
import FormActions from "./FormActions";
import SubmitInProgress from "./SubmitInProgress";

interface CaseSubmissionFormProps {
  match: any;
  id: number;
  formErrors: any;
  submitInProgress: boolean;
  fileUploadProgress: number;
  loadingForm: boolean;
  getCaseSubmission: (params: { id: string }) => void;
  loadNewCaseSubmissionForm: () => void;
  resetLoadingFormStatus: () => void;
  startUploadAndFormSubmit: () => void;
}

class CaseSubmissionForm extends React.Component<CaseSubmissionFormProps> {
  componentDidMount() {
    const id = this.props?.match?.params?.id;
    if (id) {
      // we're loading existing case submission
      this.props.getCaseSubmission({ id });
    } else {
      // we're creating new case submission
      this.props.loadNewCaseSubmissionForm();
    }
  }

  componentWillUnmount() {
    this.props.resetLoadingFormStatus();
  }

  render() {
    const canSubmit =
      !this.props.id &&
      this.props.formErrors &&
      this.props.formErrors["_case"] === null &&
      this.props.formErrors["field"] === null &&
      this.props.formErrors["project"] === null &&
      this.props.formErrors["file"] === null;

    return (
      <>
        {this.props.loadingForm ? (
          <CustomCircularProgress />
        ) : (
          <div className="grid-offset-padding-40">
            <Grid container direction="column" spacing={4}>
              {!this.props.submitInProgress ? (
                <>
                  <Grid item>
                    <FormActions
                      isExisting={Boolean(this.props.id)}
                      canSubmit={canSubmit}
                      onSubmit={this.props.startUploadAndFormSubmit}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <CaseSubmissionInformation />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <SubmitInProgress
                          fileUploadProgress={this.props.fileUploadProgress}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    id: state.caseSubmission.form.id,
    formErrors: state.caseSubmission.formErrors,
    submitInProgress: state.caseSubmission.submitInProgress,
    fileUploadProgress: state.caseSubmission.fileUploadProgress,
    loadingForm: state.caseSubmission.loadingForm,
  };
}

export default connect(mapStateToProps, {
  getCaseSubmission,
  resetLoadingFormStatus,
  loadNewCaseSubmissionForm,
  startUploadAndFormSubmit,
})(CaseSubmissionForm);
