/* router - navigation actions */
export const NAVIGATE_TO_DASHBOARD_SAGA = "NAVIGATE_TO_DASHBOARD_SAGA";

/* form actions */
export const CHANGE_CASESUBMISSION_FORM_DATA =
  "CHANGE_CASESUBMISSION_FORM_DATA";

export const CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD =
  "CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD";

// form upload
export const START_UPLOAD_AND_SUBMIT_SAGA = "START_UPLOAD_AND_SUBMIT_SAGA";
export const START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS =
  "START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS";
export const START_UPLOAD_AND_SUBMIT_SAGA_FAILURE =
  "START_UPLOAD_AND_SUBMIT_SAGA_FAILURE";

export const SET_FILE_FOR_UPLOAD = "SET_FILE_FOR_UPLOAD";
export const RESET_FILE_FOR_UPLOAD = "RESET_FILE_FOR_UPLOAD";

export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";
export const UPLOAD_FILE_DONE = "UPLOAD_FILE_DONE";

// file removal
export const REMOVE_FILE_FROM_PROJECT = "REMOVE_FILE_FROM_PROJECT";

// general case submission actions
export const FETCH_CASESUBMISSION_SAGA = "FETCH_CASESUBMISSION_SAGA";
export const FETCH_CASESUBMISSION_SAGA_SUCCESS =
  "FETCH_CASESUBMISSION_SAGA_SUCCESS";
export const FETCH_CASESUBMISSION_SAGA_FAILURE =
  "FETCH_CASESUBMISSION_SAGA_FAILURE";

export const RESET_LOADING_FORM_STATUS = "RESET_LOADING_FORM_STATUS";
export const LOAD_NEW_CASESUBMISSION_FORM = "LOAD_NEW_CASESUBMISSION_FORM";

// export const CHANGE_DISPLAYED_COLUMNS = "CHANGE_DISPLAYED_COLUMNS";

/* global */
export const CLOSE_GLOBAL_SNACKBAR = "CLOSE_GLOBAL_SNACKBAR";
export const OPEN_GLOBAL_SNACKBAR = "OPEN_GLOBAL_SNACKBAR";

export const GRAPHQL_ERROR_SAGA = "GRAPHQL_ERROR_SAGA";
