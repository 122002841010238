import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router-dom";
import LinkComponent from "../../utils/LinkComponent";

const INTERVAL_ONE_SECOND_IN_MS = 1000;

export default function ErrorPage(): ReactElement {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const intervalTimer = setInterval(
      () => setCounter(counter - 1),
      INTERVAL_ONE_SECOND_IN_MS,
    );

    return () => clearInterval(intervalTimer);
  }, [counter]);

  return (
    <>
      <Helmet>
        <title>Page not found - Redirecting...</title>
      </Helmet>
      {counter > 0 ? (
        <div className="error-page-wrapper">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <Paper className="login-page-paper">
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">
                      Requested page does not exist or could not be found.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      You will be redirected to
                      <LinkComponent to="/" replace>
                        {" "}
                        dashboard{" "}
                      </LinkComponent>
                      in {counter} seconds
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}
