import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { ReactElement } from "react";
import { formatFileSize } from "../../utils/functions";

// TODO: Merge this entire component into FileUpload.

interface UploadedFileProps {
  isSaved: boolean;
  name: string;
  size: number;
  deleteFileForUpload: () => void;
}

export default function UploadedFile(props: UploadedFileProps): ReactElement {
  return (
    <Grid container direction="column" spacing={1}>
      {props.isSaved && (
        <Grid item>
          <Typography variant="h6">Uploaded file</Typography>
        </Grid>
      )}
      {props.name && props.size && (
        <Grid item>
          <List>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar>
                  <CloudIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.name}
                secondary={formatFileSize(props.size)}
              />
              {!props.isSaved && (
                <ListItemSecondaryAction>
                  <IconButton
                    disableRipple
                    aria-label="Delete"
                    onClick={() => props.deleteFileForUpload()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        </Grid>
      )}
    </Grid>
  );
}
