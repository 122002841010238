import ApolloClient from "apollo-boost";
import { getAccessToken } from "../auth";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
  // prevent default error handler from running
  onError: () => {},
  request: async (operation) => {
    // set auth header before each request
    const headers = {
      Authorization: "Bearer " + (await getAccessToken()),
      // Authorization: "Bearer " + process.env.REACT_APP_TEMP_TOKEN
    };

    operation.setContext({ headers });
  },
});

export default client;
