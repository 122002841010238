import { TableCell, TableSortLabel } from "@mui/material";
import { MouseEventHandler, ReactElement } from "react";
import { TSort } from "../../utils/types";

interface TableHeaderCellProps<TColumn> {
  title: string;
  column: TColumn;
  sort: TSort<TColumn>;
  onSortChange: (value: TSort<TColumn>) => void;
}

export default function TableHeaderCell<T>(
  props: TableHeaderCellProps<T>,
): ReactElement {
  const clickHandler: MouseEventHandler = () => {
    if (props.sort.column === props.column && props.sort.order === "ASC") {
      props.onSortChange({ column: props.column, order: "DESC" });
    } else {
      props.onSortChange({ column: props.column, order: "ASC" });
    }
  };

  return (
    <TableCell>
      <TableSortLabel
        active={props.sort.column === props.column}
        direction={props.sort.order === "ASC" ? "asc" : "desc"}
        onClick={clickHandler}
        children={props.title}
      />
    </TableCell>
  );
}
