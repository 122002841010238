import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import debounce from "lodash.debounce";
import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import {
  changeCaseSubmissionAutocompleteField,
  changeCaseSubmissionFormField,
  resetFileForUpload,
  setFileForUpload,
} from "../../actions/caseSubmissionActions";
import AutocompleteCaseWrapper from "../../common/AutocompleteCaseWrapper";
import AutocompleteFieldWrapper from "../../common/AutocompleteFieldWrapper";
import AutocompleteProjectWrapper from "../../common/AutocompleteProjectWrapper";
import { DEBOUNCE_INPUT_DELAY } from "../../utils/constants";
import { Case, Field, Project } from "../../utils/types";
import CaseSubmissionStatuses from "./CaseSubmissionStatuses";
import FileUpload from "./FileUpload";
import StatusAndDescriptionTable from "./StatusAndDescriptionTable";
import UploadedFile from "./UploadedFile";

interface CaseSubmissionForm {
  id: number;
  validationStatus: string;
  processingStatus: string;
  loadStartTime: string;
  loadEndTime: string;
  originalFileName: string;
  blobSize: number;
  _case: Case;
  field: Field;
  project: Project;
  file: File;
  details: any;
}

interface CaseSubmissionInformationProps {
  //caseError: string;
  //fieldError: string;
  //projectError: string;
  form: CaseSubmissionForm;
  changeCaseSubmissionAutocompleteField: (
    field: string,
    selectedOption: unknown,
  ) => void;
  changeCaseSubmissionFormField: (field: string, value: string) => void;
  setFileForUpload: (file: File) => void;
  resetFileForUpload: () => void;
}

class CaseSubmissionInformation extends React.Component<CaseSubmissionInformationProps> {
  debouncedCallback = debounce(
    this.props.changeCaseSubmissionFormField,
    DEBOUNCE_INPUT_DELAY,
  );

  lastChangedField = "";

  onChangeField =
    (field: string) =>
    ({ target: { value } }: { target: { value: any } }) => {
      if (this.lastChangedField !== field) {
        this.lastChangedField = field;
        this.debouncedCallback.flush();
      }

      this.debouncedCallback(field, value);
    };

  flushDebounce = () => {
    this.debouncedCallback.flush();
  };

  onChangeDate = (field: string) => (value: any) => {
    this.props.changeCaseSubmissionFormField(field, value);
  };

  render() {
    //const { caseError, fieldError, projectError, form } = this.props;
    const {
      id,
      _case,
      field,
      project,
      file,
      originalFileName,
      blobSize,
      details,
      validationStatus,
      processingStatus,
      loadStartTime,
      loadEndTime,
    } = this.props.form;

    return (
      <>
        <Helmet>
          {id ? (
            <title>View submission - {`${id}`}</title>
          ) : (
            <title>Add new submission - D&M Petrophysics Console</title>
          )}
        </Helmet>
        <Paper className="casesubmission-form-paper">
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h6" marginBottom={2}>
                    Submission Information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {id && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          disabled
                          size="small"
                          label="Submission ID"
                          defaultValue={id}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <AutocompleteProjectWrapper
                        label="Select project *"
                        defaultValue={project}
                        disabled={Boolean(id)}
                        //error={Boolean(projectError)}
                        //helperText={projectError || ""}
                        onChange={(project) => {
                          this.props.changeCaseSubmissionAutocompleteField(
                            "project",
                            project,
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteFieldWrapper
                        label="Select field *"
                        defaultValue={field}
                        disabled={Boolean(id)}
                        //error={Boolean(fieldError)}
                        //helperText={fieldError || ""}
                        onChange={(field) => {
                          this.props.changeCaseSubmissionAutocompleteField(
                            "field",
                            field,
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteCaseWrapper
                        label="Select case *"
                        defaultValue={_case}
                        disabled={Boolean(id)}
                        //error={Boolean(caseError)}
                        //helperText={caseError || ""}
                        onChange={(_case) => {
                          this.props.changeCaseSubmissionAutocompleteField(
                            "_case",
                            _case,
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {id ? (
              <>
                <Grid item>
                  <CaseSubmissionStatuses
                    validationStatus={validationStatus}
                    processingStatus={processingStatus}
                    loadStartTime={loadStartTime}
                    loadEndTime={loadEndTime}
                  />
                </Grid>
                {details && details.length ? (
                  <Grid item>
                    <StatusAndDescriptionTable rows={details} />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Grid item>
                <FileUpload setFileForUpload={this.props.setFileForUpload} />
              </Grid>
            )}
            <Grid item>
              <UploadedFile
                isSaved={Boolean(id)}
                name={originalFileName || file?.name}
                size={blobSize || file?.size}
                deleteFileForUpload={this.props.resetFileForUpload}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    // errors
    //caseError: state.caseSubmission.formErrors.case,
    //fieldError: state.caseSubmission.formErrors.field,
    //projectError: state.caseSubmission.formErrors.project,
    // values
    form: state.caseSubmission.form,
  };
}

export default connect(mapStateToProps, {
  changeCaseSubmissionAutocompleteField,
  changeCaseSubmissionFormField,
  setFileForUpload,
  resetFileForUpload,
})(CaseSubmissionInformation);
