import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { connect } from "react-redux";
import { closeGlobalSnackbar } from "../actions/globalActions";

interface GlobalSnackbarProps {
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  closeGlobalSnackbar: () => void;
}

class GlobalSnackbar extends React.Component<GlobalSnackbarProps> {
  render() {
    const { isSnackbarOpen, snackbarMessage } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={this.props.closeGlobalSnackbar}
        message={snackbarMessage}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.props.closeGlobalSnackbar}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isSnackbarOpen: state.global.isSnackbarOpen,
    snackbarMessage: state.global.snackbarMessage,
  };
}

export default connect(mapStateToProps, { closeGlobalSnackbar })(
  GlobalSnackbar,
);
