import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { ReactElement } from "react";
import LinkComponent from "../../utils/LinkComponent";

interface FormActionsProps {
  isExisting: boolean;
  canSubmit: boolean;
  onSubmit: () => void;
}

export default function FormActions(props: FormActionsProps): ReactElement {
  return (
    <Grid container spacing={4} justifyContent="center">
      {props.isExisting ? (
        <Grid item>
          <Tooltip title={"Go back to dashboard"}>
            <div>
              <LinkComponent to="/">
                <Button variant="outlined" color="primary">
                  Go back
                </Button>
              </LinkComponent>
            </div>
          </Tooltip>
        </Grid>
      ) : (
        <>
          <Grid item>
            <Tooltip title={"Go back to dashboard and discard case submission"}>
              <div>
                <LinkComponent to="/">
                  <Button variant="outlined" color="secondary">
                    Go back
                  </Button>
                </LinkComponent>
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                props.canSubmit
                  ? "Fill all form fields, and select file(s) for upload"
                  : ""
              }
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!props.canSubmit}
                  onClick={props.onSubmit}
                >
                  Create Submission
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
}
