import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import packageJson from "../../../package.json";
import "../../scss/Footer.scss";

const currentYear = new Date().getFullYear();
const version =
  packageJson && packageJson.version ? " v" + packageJson.version : "";

export default function Footer(): ReactElement {
  return (
    <div className="app-footer">
      <AppBar position="static" color="default" className="appbar-footer">
        <Toolbar variant="regular" disableGutters className="toolbar-footer">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography>
                {currentYear} © D&M Petrophysics Console{version}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
