import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import LinkComponent from "../../utils/LinkComponent";
import CaseSubmissionTable from "../caseSubmissionTable/CaseSubmissionTable";

export default function UserDashboard(): ReactElement {
  return (
    <React.Fragment>
      <Helmet>
        <title>D&M Petrophysics Console</title>
      </Helmet>
      <Paper className="dashboard-paper">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LinkComponent to="/submission/create">
              <Button color="primary" variant="contained">
                Add new submission
              </Button>
            </LinkComponent>
          </Grid>
          <Grid item xs={12}>
            <CaseSubmissionTable />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
