import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Home from "@mui/icons-material/Home";
import { ReactElement, useState } from "react";
import logo from "../../assets/images/demac-logo-without-est.png";
import { getUserName } from "../../auth";
import "../../scss/Header.scss";
import LinkComponent from "../../utils/LinkComponent";

export default function Header(): ReactElement {
  const [fixedHeader, setFixedHeader] = useState(true);

  const msal = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const [userName] = useState(getUserName());

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <div className="app-header">
      <AppBar
        position={fixedHeader ? "fixed" : "static"}
        color="default"
        className="appbar-header"
      >
        <Toolbar variant="regular" disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item>
                  <LinkComponent to="/" title="User dashboard">
                    <Button>
                      <Home />
                      <Hidden xsDown>
                        <span className="header-button-text">
                          User dashboard
                        </span>
                      </Hidden>
                    </Button>
                  </LinkComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={0}>
                <Grid item>
                  <div>
                    {isAuthenticated ? (
                      <>
                        <IconButton
                          title="Account actions"
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                          <AccountCircle />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem disabled>
                            {isAuthenticated
                              ? `Logged in as: ${userName}`
                              : "Not logged in"}
                          </MenuItem>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={fixedHeader}
                                  onChange={(_, checked) =>
                                    setFixedHeader(checked)
                                  }
                                />
                              }
                              label="Fixed navigation"
                            />
                          </MenuItem>
                          <MenuItem onClick={() => msal.instance.logout()}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <Button variant="outlined">Log in</Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
