import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import logo from "../../assets/images/demac-logo.png";

export default function LoginPage(): ReactElement {
  const msal = useMsal();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      className="full-height-page"
    >
      <Grid item>
        <Paper className="login-page-paper">
          <Grid
            container
            spacing={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img src={logo} alt="logo" />
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2" color="textPrimary">
                This website uses Azure Active Directory authentication.
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2" color="textPrimary">
                Pressing "LOG IN" button below will open a pop-up with Microsoft
                authentication page.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => msal.instance.loginRedirect()}
              >
                Log in
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
