import { Autocomplete, TextField } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import client from "../config/apolloConfig";
import { GetCases } from "../graphql/queries/GetCases";
import { DEFAULT_AUTOCOMPLETE_FETCH_LIMIT } from "../utils/constants";
import { Case } from "../utils/types";

interface AutocompleteCaseWrapperProps {
  label: string;
  disabled: boolean;
  defaultValue: Case | null;
  onChange: (_case: Case | null | undefined) => void;
}

export default function AutocompleteCaseWrapper(
  props: AutocompleteCaseWrapperProps,
): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [cases, setCases] = useState<Case[]>([]);

  useEffect(() => {
    if (!isOpen && !userInput) return;

    const timeout = setTimeout(() => {
      setIsLoading(true);

      client
        .query({
          query: GetCases,
          fetchPolicy: "no-cache",
          variables: {
            filter: { nameContains: userInput },
            take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setCases(response.data.petrophysicsCases);
        })
        .catch(() => {
          setIsLoading(false);
          setCases([]);
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [isOpen, userInput]);

  return (
    <Autocomplete
      size="small"
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      loading={isLoading}
      options={cases}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(_, value) => {
        props.onChange(value);
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setUserInput(value);
        }
      }}
    />
  );
}
