import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { ReactElement } from "react";

export default function CustomCircularProgress(
  props: CircularProgressProps,
): ReactElement {
  return (
    <div className="grid-offset-padding-40">
      <Grid container justifyContent="center" spacing={5}>
        <Grid item>
          <CircularProgress disableShrink {...props} />
        </Grid>
      </Grid>
    </div>
  );
}
