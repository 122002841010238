import {
  Backdrop,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactElement, useEffect, useState } from "react";
import { store } from "../../App";
import { openGlobalSnackbar } from "../../actions/globalActions";
import client from "../../config/apolloConfig";
import { GetCaseSubmissions } from "../../graphql/queries/GetCaseSubmissions";
import LinkComponent from "../../utils/LinkComponent";
import {
  determineStatusHexCode,
  determineStatusText,
  formatDateTimeUS,
  formatDateUS,
} from "../../utils/functions";
import { Submission, TSort } from "../../utils/types";
import TableDateCell from "./TableDateCell";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";
import TableTextCell from "./TableTextCell";

export default function CaseSubmissionTable(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const [projectKeyFilter, setProjectKeyFilter] = useState("");
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [projectAsOfDateFilter, setProjectAsOfDateFilter] = useState("");
  const [createdAtFilter, setCreatedAtFilter] = useState("");
  const [fieldCodeFilter, setFieldCodeFilter] = useState("");
  const [fieldNameFilter, setFieldNameFilter] = useState("");
  const [caseNameFilter, setCaseNameFilter] = useState("");
  const [validationStatus, setValidationStatus] = useState<string>();
  const [processingStatus, setProcessingStatus] = useState<string>();
  const [userNameFilter, setUserNameFilter] = useState("");

  const [sort, setSort] = useState<TSort<string>>({
    column: "ID",
    order: "DESC",
  });

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  useEffect(() => {
    const filter = {
      projectKeyContains: projectKeyFilter,
      projectNameContains: projectNameFilter,
      projectAsOfDate: projectAsOfDateFilter || undefined,
      createdAt: createdAtFilter || undefined,
      fieldCodeContains: fieldCodeFilter,
      fieldNameContains: fieldNameFilter,
      caseNameContains: caseNameFilter,
      validationStatus: validationStatus,
      processingStatus: processingStatus,
      userNameContains: userNameFilter,
    };

    const skip = page * rowsPerPage;
    const take = rowsPerPage;

    setIsLoading(true);
    client
      .query({
        query: GetCaseSubmissions,
        variables: { filter, sort, skip, take },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        setIsLoading(false);
        setCount(response.data.petrophysicsSubmissionCount);
        setSubmissions(response.data.petrophysicsSubmissions);
      })
      .catch((e) => {
        setIsLoading(false);
        store.dispatch(openGlobalSnackbar({ message: e.message }));
      });
  }, [
    projectKeyFilter,
    projectNameFilter,
    projectAsOfDateFilter,
    createdAtFilter,
    fieldCodeFilter,
    fieldNameFilter,
    caseNameFilter,
    validationStatus,
    processingStatus,
    userNameFilter,
    sort,
    page,
    rowsPerPage,
  ]);

  return (
    <TableContainer>
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <Table size="small" padding="checkbox">
        <TableHead>
          <TableRow key="headerrow" style={{ backgroundColor: "#f0f0f0" }}>
            <TableCell>View</TableCell>
            <TableHeaderCell
              title="ID"
              column="ID"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Project ID"
              column="PROJECT_KEY"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Project Name"
              column="PROJECT_NAME"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Project As Of Date"
              column="PROJECT_ASOFDATE"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Create Date"
              column="CREATED_AT"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Field Code"
              column="FIELD_CODE"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Field Name"
              column="FIELD_NAME"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableCell>Case Name</TableCell>
            <TableHeaderCell
              title="Validation Status"
              column="VALIDATION_STATUS"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Processing Status"
              column="PROCESSING_STATUS"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
            <TableHeaderCell
              title="Creator Name"
              column="USER_NAME"
              sort={sort}
              onSortChange={(o) => setSort(o)}
            />
          </TableRow>
          <TableRow key="filterrow">
            <TableCell />
            <TableCell />
            <TableFilterCell
              value={projectKeyFilter}
              onChange={(v) => setProjectKeyFilter(v)}
            />
            <TableFilterCell
              value={projectNameFilter}
              onChange={(v) => setProjectNameFilter(v)}
            />
            <TableDateCell
              value={projectAsOfDateFilter}
              onChange={(v) => setProjectAsOfDateFilter(v)}
            />
            <TableDateCell
              value={createdAtFilter}
              onChange={(v) => setCreatedAtFilter(v)}
            />
            <TableFilterCell
              value={fieldCodeFilter}
              onChange={(v) => setFieldCodeFilter(v)}
            />
            <TableFilterCell
              value={fieldNameFilter}
              onChange={(v) => setFieldNameFilter(v)}
            />
            <TableFilterCell
              value={caseNameFilter}
              onChange={(v) => setCaseNameFilter(v)}
            />
            <TableCell>
              <Select
                fullWidth
                variant="standard"
                value={validationStatus || "ALL"}
                onChange={(e) =>
                  setValidationStatus(
                    e.target.value !== "ALL"
                      ? (e.target.value as string)
                      : undefined,
                  )
                }
              >
                <MenuItem value="ALL">View all</MenuItem>
                <MenuItem value="IN_QUEUE">Queued</MenuItem>
                <MenuItem value="IN_PROGRESS">In progress</MenuItem>
                <MenuItem value="SUCCESS">Success</MenuItem>
                <MenuItem value="FAILURE">Failed</MenuItem>
                <MenuItem value="WARNING">Warning</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <Select
                fullWidth
                variant="standard"
                value={processingStatus || "ALL"}
                onChange={(e) =>
                  setProcessingStatus(
                    e.target.value !== "ALL"
                      ? (e.target.value as string)
                      : undefined,
                  )
                }
              >
                <MenuItem value="ALL">View all</MenuItem>
                <MenuItem value="IN_QUEUE">Queued</MenuItem>
                <MenuItem value="IN_PROGRESS">In progress</MenuItem>
                <MenuItem value="SUCCESS">Success</MenuItem>
                <MenuItem value="FAILURE">Failed</MenuItem>
              </Select>
            </TableCell>
            <TableFilterCell
              value={userNameFilter}
              onChange={(v) => setUserNameFilter(v)}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((row, index) => (
            <TableRow
              key={row.id}
              style={{ backgroundColor: index % 2 ? "#ffffff" : "#d9f5d2" }}
            >
              <TableCell>
                <LinkComponent to={`/submission/${row.id}`}>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </LinkComponent>
              </TableCell>
              <TableTextCell text={row.id.toString()} />
              <TableTextCell text={row.project.projectKey} />
              <TableTextCell text={row.project.projectName} />
              <TableTextCell text={formatDateUS(row.project.projectAsOfDate)} />
              <TableTextCell
                text={formatDateUS(row.createdAt)}
                title={formatDateTimeUS(row.createdAt)}
              />
              <TableTextCell text={row.field.code} />
              <TableTextCell text={row.field.name} />
              <TableTextCell text={row._case.name} />
              <TableTextCell
                text={determineStatusText(row.validationStatus)}
                style={{ color: determineStatusHexCode(row.validationStatus) }}
              />
              <TableTextCell
                text={determineStatusText(row.processingStatus)}
                style={{
                  color: determineStatusHexCode(row.processingStatus),
                }}
              />
              <TableTextCell text={row.creator.name} />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              component="td"
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, p) => setPage(p)}
              onRowsPerPageChange={(e) =>
                setRowsPerPage(Number(e.target.value))
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
