import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import {
  determineSeverityColor,
  determineSeverityText,
} from "../../utils/functions";

interface StatusAndDescriptionItem {
  id: number;
  severity: string;
  description: string;
}

interface StatusAndDescriptionTableProps {
  rows: StatusAndDescriptionItem[];
}

export default function StatusAndDescriptionTable(
  props: StatusAndDescriptionTableProps,
): ReactElement {
  return (
    <TableContainer component={Grid} item>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Severity</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography
                  style={{ color: determineSeverityColor(row.severity) }}
                >
                  {determineSeverityText(row.severity)}
                </Typography>
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
