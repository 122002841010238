export const caseSubmissionValidation = (currentFormErrors, field, value) => {
  // validation
  let formErrors = { ...currentFormErrors };
  switch (field) {
    case "_case":
      // check if valid value
      if (!value || value === null || !value.id || !value.name) {
        formErrors[field] = "Invalid or missing case";
        break;
      }

      formErrors[field] = null;
      break;
    case "field":
      // check if valid value
      if (!value || value === null || !value.name) {
        formErrors[field] = "Invalid or missing field";
        break;
      }

      formErrors[field] = null;
      break;
    case "project":
      // check if valid value
      if (
        !value ||
        value === null ||
        !value.id ||
        !value.projectKey ||
        !value.projectName
      ) {
        formErrors[field] = "Invalid or missing project";
        break;
      }

      formErrors[field] = null;
      break;
    default:
  }

  return formErrors;
};
