import * as actions from "./actionTypes";

export const changeCaseSubmissionFormField = (field, value) => {
  return {
    type: actions.CHANGE_CASESUBMISSION_FORM_DATA,
    payload: { field, value },
  };
};

export const changeCaseSubmissionAutocompleteField = (
  field,
  selectedOption,
) => {
  return {
    type: actions.CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD,
    payload: { field, selectedOption },
  };
};

export const getCaseSubmission = (payload) => {
  return { type: actions.FETCH_CASESUBMISSION_SAGA, payload };
};

export const resetLoadingFormStatus = () => {
  return { type: actions.RESET_LOADING_FORM_STATUS, payload: null };
};

export const loadNewCaseSubmissionForm = () => {
  return { type: actions.LOAD_NEW_CASESUBMISSION_FORM, payload: null };
};

export const startUploadAndFormSubmit = () => {
  return { type: actions.START_UPLOAD_AND_SUBMIT_SAGA, payload: null };
};

export const setFileForUpload = (file) => {
  return { type: actions.SET_FILE_FOR_UPLOAD, payload: { file } };
};

export const resetFileForUpload = () => {
  return { type: actions.RESET_FILE_FOR_UPLOAD, payload: null };
};

export const uploadFileProgress = (blobContainer, blobName, progress) => {
  return {
    type: actions.UPLOAD_FILE_PROGRESS,
    payload: { blobContainer, blobName, progress },
  };
};
