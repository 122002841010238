import * as actions from "../actions/actionTypes";

import {
  initialCaseSubmissionState,
  defaultFormErrorsState,
  defaultCaseSubmissionFormState,
} from "../store/initialState";

import { caseSubmissionValidation } from "../store/storeFunctions";

const reducer_casesubmission = (state = initialCaseSubmissionState, action) => {
  let formErrors = null;
  let progress = null;

  switch (action.type) {
    case actions.CHANGE_CASESUBMISSION_FORM_DATA:
      let form = { ...state.form };

      switch (action.payload.field) {
        case "name":
          form.field[action.payload.field] = action.payload.value;
          break;
        default:
          form[action.payload.field] = action.payload.value;
      }

      formErrors = caseSubmissionValidation(
        state.formErrors,
        action.payload.field,
        action.payload.value,
      );

      return { ...state, formErrors, form };
    case actions.CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD:
      // TODO: consolidate this into CHANGE_CASESUBMISSION_FORM_DATA action?
      formErrors = caseSubmissionValidation(
        state.formErrors,
        action.payload.field,
        action.payload.selectedOption,
      );

      return {
        ...state,
        formErrors,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.selectedOption,
        },
      };
    case actions.RESET_LOADING_FORM_STATUS:
      return { ...state, loadingForm: true };
    case actions.LOAD_NEW_CASESUBMISSION_FORM:
      return {
        ...state,
        formErrors: defaultFormErrorsState(),
        form: defaultCaseSubmissionFormState(),
        loadingForm: false,
      };
    // getCaseSubmission (when viewing/fetching a single submission)
    case actions.FETCH_CASESUBMISSION_SAGA_SUCCESS:
      const caseSubmissionData = action.payload.data;

      return {
        ...state,
        form: caseSubmissionData,
        formErrors: defaultFormErrorsState(),
        loadingForm: false,
      };
    case actions.FETCH_CASESUBMISSION_SAGA_FAILURE:
      return { ...state };
    /**
     *
     * UPLOAD FILE AND SUBMIT FORM
     *
     *
     */
    case actions.START_UPLOAD_AND_SUBMIT_SAGA:
      return {
        ...state,
        fileUploadProgress: 0,
        submitInProgress: true,
      };
    case actions.START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS:
      return { ...state, submitInProgress: false };
    case actions.START_UPLOAD_AND_SUBMIT_SAGA_FAILURE:
      return { ...state, submitInProgress: false };
    /**
     *
     *  FILE ACTIONS REDUCER
     *
     */
    case actions.UPLOAD_FILE_PROGRESS:
      // blobContainer and blobName in payload currently unused
      // potentially useful for future feature expansion
      progress = action.payload.progress * 100;

      return {
        ...state,
        fileUploadProgress: progress,
      };
    case actions.UPLOAD_FILE_DONE:
      return {
        ...state,
        fileUploadProgress: 100,
      };
    case actions.SET_FILE_FOR_UPLOAD:
      const file = action.payload.file;
      return {
        ...state,
        formErrors: { ...state.formErrors, file: null },
        form: {
          ...state.form,
          file: {
            name: file.name,
            size: file.size,
            type: file.type,
            fileObject: file,
          },
        },
      };
    case actions.RESET_FILE_FOR_UPLOAD:
      return {
        ...state,
        formErrors: { ...state.formErrors, file: "Missing file!" },
        form: { ...state.form, file: null },
      };
    default:
      return state;
  }
};

export default reducer_casesubmission;
