import gql from "graphql-tag";

export const CreateCaseSubmissionMutation = gql`
  mutation (
    $mimeType: String!
    $blobSize: Float!
    $blobName: String!
    $blobContainer: String!
    $originalFileName: String!
    $caseId: ID!
    $fieldId: ID!
    $projectId: ID!
  ) {
    petrophysicsSubmit(
      mimeType: $mimeType
      blobSize: $blobSize
      blobName: $blobName
      blobContainer: $blobContainer
      originalFileName: $originalFileName
      caseId: $caseId
      fieldId: $fieldId
      projectId: $projectId
    ) {
      id
    }
  }
`;
