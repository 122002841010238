import { TableCell } from "@mui/material";
import { CSSProperties, ReactElement } from "react";

interface TableTextCellProps {
  text: string;
  title?: string;
  style?: CSSProperties;
}

export default function TableTextCell(props: TableTextCellProps): ReactElement {
  return (
    <TableCell
      title={props.title || props.text}
      style={{
        ...props.style,
        maxWidth: "120px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {props.text}
    </TableCell>
  );
}
