import moment from "moment";

/**
 * determine colors for validation and processing status text
 */
export const determineStatusHexCode = (status) => {
  switch (status) {
    case "IN_QUEUE":
      return "#999999";
    case "SUCCESS":
      return "#009422";
    case "FAILURE":
      return "#D80000";
    case "IN_PROGRESS":
      return "#65B2FF";
    case "WARNING":
      return "#FEC200";
    default:
      return "inherit";
  }
};

/**
 * determine text for validation and processing status from enum/const
 */
export const determineStatusText = (status) => {
  switch (status) {
    case "IN_QUEUE":
      return "Queued";
    case "SUCCESS":
      return "Success";
    case "FAILURE":
      return "Failed";
    case "IN_PROGRESS":
      return "In progress";
    case "WARNING":
      return "Warning";
    default:
      return "Invalid value";
  }
};

export const determineSeverityColor = (severity) => {
  switch (severity) {
    case "ERROR":
      return "#D80000";
    case "WARNING":
      return "#FEC200";
    default:
      return "inherit";
  }
};

export const determineSeverityText = (severity) => {
  switch (severity) {
    case "ERROR":
      return "Error";
    case "WARNING":
      return "Warning";
    default:
      return severity;
  }
};

export const determineFileUploadType = (fileType) => {
  switch (fileType) {
    case "text/plain":
    case "text/csv":
    case "application/pdf":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
    case "application/epub+zip":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/rtf":
    case "application/vnd.visio":
    case "application/xml":
    case "text/xml":
      return "PROJECT_DOCUMENT";
    case "image/jpeg":
    case "image/png":
    case "image/x-icon":
    case "image/vnd.microsoft.icon":
    case "image/bmp":
    case "image/gif":
    case "image/webp":
      return "IMAGE";
    default:
      return "OTHER";
  }
};

export const determineFileDocumentType = (fileType) => {
  switch (fileType) {
    case "text/plain":
    case "text/csv":
    case "text/xml":
    case "application/xml":
    case "application/rtf":
    case "application/vnd.oasis.opendocument.text":
      return "TXT";
    case "application/pdf":
      return "PDF";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/vnd.oasis.opendocument.spreadsheet":
      return "EXCEL";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return "WORD";
    case "image/jpeg":
    case "image/png":
    case "image/x-icon":
    case "image/vnd.microsoft.icon":
    case "image/bmp":
    case "image/gif":
    case "image/webp":
      return "IMAGE";
    default:
      return "BLOB";
  }
};

export const formatDateUS = (date) =>
  moment(moment.utc(date)).format("MM/DD/YYYY");

export const formatDateTimeUS = (date) =>
  moment(moment.utc(date)).format("MM/DD/YYYY H:mm:ss A");

export const getFileExtension = (fileName) => {
  var extension = fileName.split(".");
  if (
    extension.length === 1 ||
    (extension[0] === "" && extension.length === 2)
  ) {
    return "";
  }
  return extension.pop().toLowerCase();
};

export const formatFileSize = (fileSizeInBytes) => {
  if (fileSizeInBytes < 1024) {
    return fileSizeInBytes + " bytes";
  } else if (fileSizeInBytes < 1024 * 1024) {
    return (fileSizeInBytes / 1024).toFixed(0) + " KB";
  } else if (fileSizeInBytes < 1024 * 1024 * 1024) {
    return (fileSizeInBytes / 1024 / 1024).toFixed(1) + " MB";
  } else {
    return (fileSizeInBytes / 1024 / 1024 / 1024).toFixed(2) + " GB";
  }
};
