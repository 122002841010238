import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

interface SubmitInProgressProps {
  fileUploadProgress: number;
}

export default function SubmitInProgress(
  props: SubmitInProgressProps,
): ReactElement {
  return (
    <>
      <Helmet>
        <title>Uploading...</title>
      </Helmet>
      <Paper className="casesubmission-form-paper">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h6" align="center">
              Uploading file and submitting...
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" align="center">
                  Please do not close this tab until operation finishes.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" align="center">
                  You will be redirected back to dashboard on completion.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {props.fileUploadProgress > 0 ? (
            <>
              <Grid item>
                <Grid container spacing={0} direction="column">
                  <Grid item>
                    <Typography>Upload in progress</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={props.fileUploadProgress}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Typography variant="body2">
                          {props.fileUploadProgress.toFixed(0)}%
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
}
