import * as actions from "../actions/actionTypes";
import { put, call, select } from "redux-saga/effects";

import {
  BlockBlobURL,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential,
  StorageURL,
  Aborter,
} from "@azure/storage-blob";

import client from "../config/apolloConfig";

import { GetCaseSubmission } from "../graphql/queries/GetCaseSubmission";
import { GenerateAzureBlobUrl } from "../graphql/queries/GenerateAzureBlobUrl";

import { CreateCaseSubmissionMutation } from "../graphql/mutations/CreateCaseSubmission";

import {
  ROUTE_TO_DASHBOARD_ERROR_PAGE,
  UNABLE_TO_FIND_CASESUBMISSION,
  ROUTE_TO_DASHBOARD,
} from "../utils/constants";
import { NO_FILE, INVALID_SAS_URL } from "../utils/errorConstants";

import { getFileExtension } from "../utils/functions";
import {
  throttledFileProgress,
  errorHandlerSaga,
  handleSnackbar,
  handleRedirect,
} from "../utils/sagaHelperFunctions";

export function* fetchCaseSubmissionSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetCaseSubmission,
        variables: { id: action.payload.id },
      }),
    );

    const data = result?.data?.petrophysicsSubmission || null;

    if (!data) {
      throw UNABLE_TO_FIND_CASESUBMISSION;
    }

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield handleRedirect(true, ROUTE_TO_DASHBOARD_ERROR_PAGE);

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_FAILURE,
      payload: null,
    });
  }
}

/*
 *
 * SUBMIT SAGA
 *
 */
export function* uploadFileAndSubmitFormSaga(action) {
  try {
    /**
     *
     * START FILE UPLOAD PROCESS
     *
     */

    let caseSubmissionForm = {
      ...(yield select((state) => state.caseSubmission.form)),
    };

    const file = caseSubmissionForm?.file || null;

    if (!file) {
      throw NO_FILE;
    }

    // generate url for a new file
    const generateSASUrlResult = yield call(() =>
      client.query({
        query: GenerateAzureBlobUrl,
        variables: {
          projectId: caseSubmissionForm.project.id,
          fieldId: caseSubmissionForm.field.id,
          extension: getFileExtension(file.name),
        },
        fetchPolicy: "no-cache",
      }),
    );

    const sasURL =
      generateSASUrlResult?.data?.petrophysicsGenerateUploadBlobSASUrl || null;

    if (!sasURL) {
      throw INVALID_SAS_URL;
    }

    // extract container and blob name from url
    const urlParts = /(https:\/\/.*?)\/(.*?)\/(.*?)\?(.*)/.exec(sasURL);
    const blobContainer = urlParts[2];
    const blobName = urlParts[3];

    // https://github.com/Azure/azure-storage-node/issues/534
    const pipeline = StorageURL.newPipeline(new AnonymousCredential());

    const blockBlobURL = new BlockBlobURL(sasURL, pipeline);

    // upload file
    yield uploadBrowserDataToBlockBlob(
      Aborter.none,
      file.fileObject,
      blockBlobURL,
      {
        // eslint-disable-next-line no-loop-func
        progress: (ev) => {
          const progress = ev.loadedBytes / file.size;

          throttledFileProgress(blobContainer, blobName, progress);
        },
      },
    );

    /* we cannot rely that uploads' progress callback will
     * correctly send last expected action
     * so send one manually
     */
    yield put({
      type: actions.UPLOAD_FILE_DONE,
      payload: null,
    });

    yield throttledFileProgress.flush();

    /**
     *
     * START CASE SUBMISSION... SUBMISSION
     *
     */
    yield call(() =>
      client.mutate({
        mutation: CreateCaseSubmissionMutation,
        variables: {
          projectId: caseSubmissionForm.project.id,
          fieldId: caseSubmissionForm?.field?.id,
          caseId: caseSubmissionForm?._case?.id,
          originalFileName: file.name,
          blobContainer,
          blobName,
          blobSize: file.size,
          mimeType: file.type,
        },
      }),
    );

    // dispatch action on all uploads finished
    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS,
      payload: null,
    });

    yield handleSnackbar(true, "Case submission success!");
    yield handleRedirect(true, ROUTE_TO_DASHBOARD);
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_FAILURE,
      payload: null,
    });
  }
}
