import gql from "graphql-tag";

export const GetCases = gql`
  query (
    $filter: PetrophysicsCaseFilter
    $sort: [PetrophysicsCaseSort!]
    $skip: Int
    $take: Int
  ) {
    petrophysicsCases(filter: $filter, sort: $sort, skip: $skip, take: $take) {
      id
      name
    }
  }
`;
