/* GLOBAL STATES */
export const initialGlobalState = {
  isSnackbarOpen: false,
  snackbarMessage: "",
};

export const defaultCaseSubmissionFormState = () => ({
  project: null,
  field: null,
  _case: null,
  file: null,
});

export const defaultFormErrorsState = () => ({});

export const initialCaseSubmissionState = {
  // submit in progress related
  submitInProgress: false,
  fileUploadProgress: 0,
  // form
  loadingForm: true,
  formErrors: defaultFormErrorsState(),
  form: defaultCaseSubmissionFormState(),
};
