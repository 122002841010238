import * as actions from "./actionTypes";

export const closeGlobalSnackbar = () => {
  return { type: actions.CLOSE_GLOBAL_SNACKBAR, payload: {} };
};

export const openGlobalSnackbar = ({ message }) => {
  return { type: actions.OPEN_GLOBAL_SNACKBAR, payload: { message } };
};

// export const triggerGraphQLError = payload => {
//   return { type: actions.GRAPHQL_ERROR_SAGA, payload };
// };
