import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import {
  determineStatusHexCode,
  determineStatusText,
  formatDateTimeUS,
} from "../../utils/functions";

interface CaseSubmissionStatusesProps {
  validationStatus: string;
  processingStatus: string;
  loadStartTime: string;
  loadEndTime: string;
}

export default function CaseSubmissionStatuses(
  props: CaseSubmissionStatusesProps,
): ReactElement {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">Status</Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Validation Status</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: determineStatusHexCode(props.validationStatus),
                  }}
                >
                  {determineStatusText(props.validationStatus)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Processing Status</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: determineStatusHexCode(props.processingStatus),
                  }}
                >
                  {determineStatusText(props.processingStatus)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Load Start Time:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {props.loadStartTime
                    ? formatDateTimeUS(props.loadStartTime)
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Load End Time:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {props.loadEndTime
                    ? formatDateTimeUS(props.loadEndTime)
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
