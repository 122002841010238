import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ThemeProvider, createTheme } from "@mui/material";
import { ConnectedRouter } from "connected-react-router";
import { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { msalInstance } from "./auth";
import GlobalSnackbar from "./common/GlobalSnackbar";
import CaseSubmissionForm from "./components/caseSubmissionForm/CaseSubmissionForm";
import ErrorPage from "./components/error/ErrorPage";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import LoginPage from "./components/login/LoginPage";
import UserDashboard from "./components/userDashboard/UserDashboard";
import "./scss/Main.scss";
import { configureStore, history } from "./store/configurationStore";

export const store = configureStore();

const theme = createTheme({});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <>
              <Helmet>
                <title>D&M Petrophysics Console</title>
              </Helmet>
              <ThemeProvider theme={theme}>
                <MsalProvider instance={msalInstance}>
                  <UnauthenticatedTemplate>
                    <Switch>
                      <Route path="/" exact component={LoginPage} />
                      <Route
                        path="*"
                        render={(props) => (
                          <Redirect
                            to={"/?redirect=" + props.location.pathname}
                          />
                        )}
                      />
                    </Switch>
                  </UnauthenticatedTemplate>
                  <AuthenticatedTemplate>
                    <Header />
                    <Switch>
                      <Route
                        path="/"
                        exact
                        render={(props) => {
                          const searchParams = new URLSearchParams(
                            props.location.search,
                          );
                          const redirect = searchParams.get("redirect");
                          if (redirect) {
                            return <Redirect to={redirect} />;
                          } else {
                            return <UserDashboard />;
                          }
                        }}
                      />
                      <Route
                        path="/submission/create"
                        exact
                        component={CaseSubmissionForm}
                      />
                      <Route
                        path="/submission/:id"
                        component={CaseSubmissionForm}
                      />
                      <Route path="*" component={ErrorPage} />
                    </Switch>
                    <Footer />
                    <GlobalSnackbar />
                  </AuthenticatedTemplate>
                </MsalProvider>
              </ThemeProvider>
            </>
          </HelmetProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
